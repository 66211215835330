import { call, put, select } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';

import { isAuthenticateSelector } from '../../selectors/login';

import { userSendLostPasswordSuccessful, userSendLostPasswordFailed } from '../../redux/user/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*({ payload }) {
    try {
        yield call(UserClient.sendLostPasswordEmail, payload);
        yield put(userSendLostPasswordSuccessful());

        const isAuthenticated = yield select(isAuthenticateSelector);
        yield put(messagesShow(isAuthenticated ? 'sendLostPasswordEmailAuth' : 'sendLostPasswordEmailOk', 'success'));
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else if (error.code === 424) {
            yield put(userSendLostPasswordFailed());
            yield put(messagesShow('emailNotFound', 'error'));
        } else {
            yield put(userSendLostPasswordFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }
}

export const SETUP_STRIPE_INTENT = '@app/SETUP_STRIPE_INTENT';
export const SETUP_STRIPE_INTENT_SUCCESSFUL = '@app/SETUP_STRIPE_INTENT_SUCCESSFUL';
export const RESET_STRIPE_CLIENT_SECRET = '@app/RESET_STRIPE_CLIENT_SECRET';
export const REQUEST_STRIPE_SYNCHRONIZE = '@app/REQUEST_STRIPE_SYNCHRONIZE';

export const SETUP_ADYEN_INTENT = '@app/SETUP_ADYEN_INTENT';
export const SET_ADYEN_3DS_REQUIRED = '@app/SET_ADYEN_3DS_REQUIRED';
export const SET_ADYEN_3DS_ACTION = '@app/SET_ADYEN_3DS_ACTION';
export const REQUEST_ADYEN_SYNCHRONIZE = '@app/REQUEST_ADYEN_SYNCHRONIZE';
export const REQUEST_ADYEN_SYNCHRONIZE_SUCCESSFUL = '@app/REQUEST_ADYEN_SYNCHRONIZE_SUCCESSFUL';

export const GET_PUB_KEY_INTENT_SUCCESSFUL = '@app/GET_PUB_KEY_INTENT_SUCCESSFUL';
export const RESET_STRIPE = '@app/RESET_STRIPE';
export const GET_PUB_KEY = '@app/GET_PUB_KEY';
export const GET_PUB_KEY_SUCCESS = '@app/GET_PUB_KEY_SUCCESS';

export const SET_PAYMENT_PROVIDER = '@app/SET_PAYMENT_PROVIDER';
export const SET_DEFAULT_LOCALE = '@app/SET_DEFAULT_LOCALE';

import {
    SETUP_STRIPE_INTENT_SUCCESSFUL,
    SETUP_STRIPE_INTENT,
    RESET_STRIPE_CLIENT_SECRET,
    SETUP_ADYEN_INTENT,
    SET_ADYEN_3DS_REQUIRED,
    SET_ADYEN_3DS_ACTION,
    REQUEST_ADYEN_SYNCHRONIZE,
    REQUEST_ADYEN_SYNCHRONIZE_SUCCESSFUL,
    RESET_STRIPE,
    GET_PUB_KEY,
    GET_PUB_KEY_INTENT_SUCCESSFUL,
    GET_PUB_KEY_SUCCESS,
    REQUEST_STRIPE_SYNCHRONIZE,
    SET_DEFAULT_LOCALE,
    SET_PAYMENT_PROVIDER,
} from './types';

export const setupStripeIntent = paymentMethod => ({ type: SETUP_STRIPE_INTENT, paymentMethod });

export const setupStripeIntentSuccessful = payload => ({ type: SETUP_STRIPE_INTENT_SUCCESSFUL, payload });

export const requestStripeSynchronized = () => ({ type: REQUEST_STRIPE_SYNCHRONIZE });

export const resetStripe = () => ({ type: RESET_STRIPE });

export const resetStripeClientSecret = () => ({ type: RESET_STRIPE_CLIENT_SECRET });

export const setupAdyenIntent = (payload, toggleAdyenModal) => ({
    type: SETUP_ADYEN_INTENT,
    payload,
    toggleAdyenModal,
});

export const setAdyen3dsRequired = payload => ({ type: SET_ADYEN_3DS_REQUIRED, payload });

export const setAdyen3dsAction = payload => ({ type: SET_ADYEN_3DS_ACTION, payload });

export const requestAdyenSynchronize = payload => ({ type: REQUEST_ADYEN_SYNCHRONIZE, payload });

export const requestAdyenSynchronizeSuccessful = payload => ({ type: REQUEST_ADYEN_SYNCHRONIZE_SUCCESSFUL, payload });

export const getPubKey = () => ({ type: GET_PUB_KEY });

export const getPubKeySuccess = payload => ({ type: GET_PUB_KEY_SUCCESS, payload });

export const getPubKeyIntentSuccessful = payload => ({ type: GET_PUB_KEY_INTENT_SUCCESSFUL, payload });

export const setPaymentProviderAction = payload => ({ type: SET_PAYMENT_PROVIDER, payload });

export const setDefaultLocaleAction = payload => ({ type: SET_DEFAULT_LOCALE, payload });

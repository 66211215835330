import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import pure from 'recompose/pure';

import CssBaseline from '@material-ui/core/CssBaseline';
import createTheme from '@material-ui/core/styles/createTheme';
import { ThemeProvider } from '@material-ui/styles';
import 'typeface-roboto';

import Loadable from 'react-loadable';

import GoogleTagManager from './components/GoogleTagManager/GoogleTagManager';
import getConfiguration from './api/Configuration';
import {
    getValidLang,
    getLocalLanguage,
    saveLocalLanguage,
    saveDefaultFleetLanguage,
    getLabelsForLanguage,
    getAvailableLocales,
} from './api/Locale';
import OpenIDClient from './api/OpenIDClient';
import UserClient from './api/UserClient';
import BookingClient from './api/BookingClient';
import StationClient from './api/StationClient';
import VehicleClient from './api/VehicleClient';

import Loading from './components/Popup/Loading';
import { ConfigurationProvider } from './components/Contexts/Configuration';
import { AclProvider } from './components/Contexts/Acl';
import { LocaleProvider, initSetLanguage } from './components/Contexts/Locale';

import configureStore from './redux/configureStore';
import sagas from './sagas';
import { loginSetAuthenticate } from './redux/login/actions';
import { setDefaultLocaleAction } from './redux/app/actions';
import { getSessionStorageItem } from './helpers/LocalStorage';

import './importAllLocales';

const LoadingComponent = ({ pastDelay }) => (pastDelay ? <Loading /> : null);

LoadingComponent.propTypes = {
    pastDelay: PropTypes.bool,
};

const Root = Loadable({
    loader: () => import(/* webpackChunkName: "Root" */ './components/Root'),
    loading: LoadingComponent,
    modules: ['Root'],
});

const StartApp = Loadable({
    loader: () => import(/* webpackChunkName: "StartApp" */ './components/Popup/StartApp'),
    loading: LoadingComponent,
    modules: ['StartApp'],
});

const enhance = compose(
    withState('isLoading', 'setLoading', true),
    withState('client', 'setClient', null),
    withState(
        'theme',
        'setTheme',
        createTheme({
            typography: {
                useNextVariants: true,
            },
        })
    ),
    withState('config', 'setConfig', {}),
    withState('locale', 'setLocale', {}),
    withState('language', 'setLanguage', ''),
    withState('store', 'setStore', null),
    withState('availableLanguages', 'setAvailableLanguages', []),
    withHandlers({
        changeFavicon: () => src => {
            const href = `${src}?v=${Math.random()}`; // for avoiding cache the image
            let link =
                document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');

            if (!link) {
                link = document.createElement('link');
                link.id = 'favicon';
                link.rel = 'shortcut icon';
                document.head.appendChild(link);
            }

            link.href = href;
        },
        loadFaviconImage: () => (imageSrc, good, bad) => {
            const img = new Image();
            img.onload = good;
            img.onerror = bad;
            img.src = imageSrc;
        },
    }),
    withHandlers({
        loadGoogleFont: () => fontFamily => {
            const fontToDownload = fontFamily
                .split(',')[0]
                .trim()
                .split(' ')
                .join('+');
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = `https://fonts.googleapis.com/css?family=${fontToDownload}`;
            document.head.appendChild(link);
        },
    }),
    withHandlers({
        onSelectedClient: ({
            setClient,
            setTheme,
            setLoading,
            setConfig,
            setLocale,
            setAvailableLanguages,
            setLanguage,
            setStore,
            changeFavicon,
            loadFaviconImage,
            loadGoogleFont,
        }) => async client => {
            setClient(client);

            const { themes, ...config } = await getConfiguration(client);
            const language = getLocalLanguage() || config.api.defaultLang;
            let alternateTranslations = [];
            try {
                alternateTranslations = JSON.parse(config.api.alternateTranslations.overrides);
            } catch (e) {
                console.error('Error parsing alternate translations', e);
            }
            const localization = await getLabelsForLanguage(language, alternateTranslations);
            const allAvailableLanguages = await getAvailableLocales();
            const availableLanguages = allAvailableLanguages
                .filter(key => config.api.availableLanguages.includes(key.lang_iso))
                .map(key => ({ name: key.lang_name, code: key.lang_iso }));
            document.title = config.api.title;

            OpenIDClient.initOpenID(config.api);
            UserClient.init(config.api);
            BookingClient.init(config.api);
            StationClient.init(config.api);
            VehicleClient.init(config.api);

            if (OpenIDClient.authenticatedOpenID.isAuthenticate()) {
                try {
                    await OpenIDClient.authenticatedOpenID.fetchRefreshToken();
                } catch {
                    OpenIDClient.authenticatedOpenID.logout();
                }
            }

            const { store, run } = configureStore();
            store.dispatch(loginSetAuthenticate(OpenIDClient.authenticatedOpenID.isAuthenticate()));
            store.dispatch(setDefaultLocaleAction(config.api.defaultLocale));

            initSetLanguage(lgSelected => {
                const lg = getValidLang(lgSelected, config.api.availableLanguages, config.api.defaultLang);
                setLanguage(lg);
                saveLocalLanguage(lg);
                saveDefaultFleetLanguage(config.api.defaultLang);
                moment.locale(lg.toLowerCase().replace('_', '-'));
            });

            run(sagas);
            setStore(store);
            setConfig({ ...config, api: { ...config.api, iconModele: themes.iconModele } });
            setAvailableLanguages(availableLanguages);
            setLocale(localization.locale);
            const lg = getValidLang(getLocalLanguage(), config.api.availableLanguages, config.api.defaultLang);
            setLanguage(lg);
            saveLocalLanguage(lg);
            saveDefaultFleetLanguage(config.api.defaultLang);
            moment.locale(lg.toLowerCase().replace('_', '-'));
            if (themes.typography.fontFamily) {
                loadGoogleFont(themes.typography.fontFamily);
            }
            setTheme(createTheme(themes));
            if (themes.favicon) {
                const iconPath = themes.favicon;
                loadFaviconImage(
                    iconPath,
                    () => changeFavicon(iconPath),
                    () => changeFavicon('/favicon.png')
                );
            }
            setLoading(false);
        },
        onSelectedLanguage: ({ config, setLanguage, setLocale }) => async language => {
            const lg = getValidLang(language, config.api.availableLanguages, config.api.defaultLang);
            const alternateTranslations = JSON.parse(config.api.alternateTranslations.overrides) || [];
            const localization = await getLabelsForLanguage(lg, alternateTranslations);
            setLocale(localization.locale);
            setLanguage(lg);
            saveLocalLanguage(lg);
            moment.locale(lg.toLowerCase().replace('_', '-'));
        },
    }),
    lifecycle({
        componentDidMount() {
            const isDevelopment = process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development';
            if (!isDevelopment) {
                const {
                    location: { hostname },
                } = window;
                this.props.onSelectedClient(hostname);
            } else if (isDevelopment && getSessionStorageItem('selectedHost')) {
                this.props.onSelectedClient(getSessionStorageItem('selectedHost'));
            }
        },
    }),
    pure
);

const history = createBrowserHistory();

const App = ({
    isLoading,
    theme,
    onSelectedClient,
    client,
    config,
    locale,
    language,
    onSelectedLanguage,
    availableLanguages,
    store,
}) => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Fragment>
                    <CssBaseline />
                    {isLoading ? (
                        <StartApp isChooseClient={!client} onSelectedClient={onSelectedClient} />
                    ) : (
                        <ConfigurationProvider {...config}>
                            <AclProvider acl={config.acl}>
                                <LocaleProvider
                                    language={language}
                                    l10n={locale}
                                    onSelectedLanguage={onSelectedLanguage}
                                    availableLanguages={availableLanguages}
                                >
                                    <Provider store={store}>
                                        <MuiPickersUtilsProvider
                                            utils={MomentUtils}
                                            libInstance={moment}
                                            locale={moment.locale()}
                                        >
                                            <Router history={history}>
                                                <Root />
                                            </Router>
                                        </MuiPickersUtilsProvider>
                                    </Provider>
                                </LocaleProvider>
                            </AclProvider>
                            <GoogleTagManager gtmId={config.googleTagManagerId} />
                        </ConfigurationProvider>
                    )}
                </Fragment>
            </ThemeProvider>
        </>
    );
};

App.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    theme: PropTypes.shape({}).isRequired,
    onSelectedClient: PropTypes.func.isRequired,
    client: PropTypes.string,
    config: PropTypes.shape({
        acl: PropTypes.shape({}),
        googleTagManagerId: PropTypes.string,
    }).isRequired,
    store: PropTypes.shape({}),
    locale: PropTypes.shape({}),
    availableLanguages: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    language: PropTypes.string,
    onSelectedLanguage: PropTypes.func.isRequired,
};

export default enhance(App);

import { createSelector } from 'reselect';

const stateSelector = state => state.app;
export const pubKeySelector = createSelector(stateSelector, state => state && state.stripe && state.stripe.pubKey);
export const adyenPubKeySelector = createSelector(stateSelector, state => state.adyen.pubKey);
export const clientSecretSelector = createSelector(stateSelector, state => state.stripe.clientSecret);
export const setupIntentIdSelector = createSelector(stateSelector, state => state.stripe.setupIntentId);
export const appFetchingSelector = createSelector(stateSelector, state => state.fetching);
export const adyenNextActionSelector = createSelector(stateSelector, state => state.adyen.nextAction);
export const adyenStatus3dsSelector = createSelector(stateSelector, state => state.adyen.status3ds);
export const adyenIs3dsRequiredSelector = createSelector(stateSelector, state => state.adyen.is3dsRequired);
export const paymentProviderSelector = createSelector(stateSelector, state => state.paymentProvider);
export const defaultLocaleSelector = createSelector(stateSelector, state => state.defaultLocale);

import { createSelector } from 'reselect';
import {
    sortArrayByProfileType,
    defaultFreePlan,
    SUBSCRIPTION_STATUS_ACTIVE,
    CONST_PROFILE_TYPE_SINGLE,
} from '../helpers/user';
import { defaultLocaleSelector } from './app';

const stateSelector = state => state.user;

export const documentsSelector = createSelector(stateSelector, state => state.documents);
export const documentTypesSelector = createSelector(stateSelector, state => state.documentTypes);
export const documentByServiceSelector = createSelector(stateSelector, state => state.documentByService);
export const meSelector = createSelector(stateSelector, state => state.me);
export const userIdSelector = createSelector([meSelector], me => (me && me.id) || null);
export const localeSelector = createSelector(
    [meSelector, defaultLocaleSelector],
    (me, defaultLocale) =>
        (me && me.locale) ||
        defaultLocale ||
        (navigator && navigator.language && navigator.language.replace('-', '_')) ||
        'en_GB'
);

export const entityIdSelector = createSelector([meSelector], user => {
    let { entityId } = user;
    if (!entityId) {
        const personalProfile =
            user.profiles && user.profiles.find(profile => profile.type === CONST_PROFILE_TYPE_SINGLE);
        entityId = personalProfile && personalProfile.entityId;
    }
    return entityId;
});
export const paymentDetailsSelector = createSelector(stateSelector, state => state.paymentDetails);
export const paymentDetailsBusinessSelector = createSelector(stateSelector, state => state.paymentDetailsBusiness);
export const fetchingSelector = createSelector(stateSelector, state => state.fetching);
export const profileTabSelector = createSelector(stateSelector, state => state.profileTab);
export const updatingSelector = createSelector(stateSelector, state => state.updating);
export const servicesSelector = createSelector(stateSelector, state => state.services);
export const userServicesWithStatusSelector = createSelector(stateSelector, state => state.userProfilesWithServices);

export const listProfileSelector = createSelector(meSelector, state =>
    state && state.profiles && Array.isArray(state.profiles) ? sortArrayByProfileType(state.profiles) : []
);
export const subscriptionsSelector = createSelector(stateSelector, state => state.subscriptions);
export const allSubscriptionPlansSelector = createSelector(stateSelector, state => state.allSubscriptionPlans);
export const profileIdSelector = createSelector(stateSelector, state => state.me.profile.profileId);
export const profileSelector = createSelector(stateSelector, state => state.me.profile);
export const billingGroupSelector = createSelector(stateSelector, state => state.me.profile.billingGroup);

export const subscriptionIdSelector = createSelector(stateSelector, state => state.subscriptions[0].id);

export const defaultPricingSelector = createSelector(stateSelector, state => state.defaultPricing);

export const activeSubscriptionPlanSelector = createSelector([subscriptionsSelector], userSubscriptions => {
    if (userSubscriptions.length > 0) {
        const subscription = userSubscriptions[0];
        return subscription.status === SUBSCRIPTION_STATUS_ACTIVE ? subscription.plan : defaultFreePlan;
    }
    return defaultFreePlan;
});
export const nextSubscriptionPlanSelector = createSelector([subscriptionsSelector], userSubscriptions => {
    if (userSubscriptions.length > 0) {
        const subscription = userSubscriptions[0];
        return subscription.status === SUBSCRIPTION_STATUS_ACTIVE && subscription.unsubscribeDate
            ? defaultFreePlan
            : false;
    }
    return false;
});
export const updatePaymentMethodSuccessSelector = createSelector(
    stateSelector,
    state => state.updatePaymentMethodSuccess
);

export const resetPasswordSuccessSelector = createSelector(stateSelector, state => state.resetPasswordSuccess);
